import React, { useEffect, useState } from "react";
import ActivitiesItem from "./ActivitiesItem";
import ActivitiesData from "../data/ActivitiesData";

export default function AllActivities() {
  const [data, setData] = useState(ActivitiesData);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const sortedActivities = Array.isArray(data)
    ? data.sort((a, b) => new Date(b.date) - new Date(a.date))
    : [];

  console.log(sortedActivities); // Check if sortedActivities is populated

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <div className="container mt-3">
      <h2 className="text-center mt-4 mb-4" style={{ color: "#06b4cb" }}>
        ALL NEWS AND ACTIVITIES
      </h2>
      <div className="row">
        {sortedActivities.map((activity) => {
          console.log(activity); // Debug each activity
          return (
            <div
              key={activity.id}
              className={`mb-4 ${
                isMobile ? "col-12" : "col-12 col-sm-6 col-md-6 col-lg-4"
              }`}
            >
              <ActivitiesItem
                id={activity.id}
                title={activity.title}
                description={activity.description}
                image={activity.image}
                author={activity.author}
                date={activity.date}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
