import React, { useEffect, useState } from "react";
import HealthTipsItem from "../HealthTipsItem";
import "./Home.css";
import Button from "../Button";
import HealthTipsData from "../../data/HealthTipsData"; // Import your activities data

export default function HealthTips() {
  const [data, setData] = useState(HealthTipsData); // Use static data from ActivitiesData

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Sort activities by published date (newest first)
  const sortedtips = Array.isArray(data)
    ? data.sort((a, b) => new Date(b.date) - new Date(a.date))
    : [];

  // Get the latest 4 activities or 2 based on screen size
  const latesttips = isMobile
    ? sortedtips.slice(0, 2)
    : sortedtips.slice(0, 5);

  const getStyle = {
    Gap: {
      marginTop: isMobile ? "20px" : "40px",
      marginBottom: isMobile ? "20px" : "40px",
      justifyContent: isMobile ? "space-between" : "center",
    },
  };

  return (
    <div
      style={{
        backgroundColor: "#b8f7ff41",
        marginTop: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        marginBottom: "20px",
      }}
    >
      <div className="container">
        {isMobile ? (
          <h2
            className="text-center mt-3 mb-2"
            style={{ color: "#06b4cb", fontSize: "18px" }}
          >
            DIABETES EDUCATION
          </h2>
        ) : (
          <h2
            className="text-center mt-5 mb-2"
            style={{ color: "#06b4cb", fontSize: "24px" }}
          >
            DIABETES EDUCATION
          </h2>
        )}

        <div className="row" style={getStyle.Gap}>
          {latesttips.map((tips) => (
            <div
              key={tips.id}
              className={`mb-2 ${
                isMobile ? "col-12" : "col-12 col-sm-8 col-md-6 col-lg-2"
              } mx-2`}
            >
              <HealthTipsItem
                id={tips.id}
                title={tips.title}
                description={tips.description}
                image={tips.image}
                author={tips.author}
                date={tips.date}
              />
            </div>
          ))}
        </div>
        <Button to="/healthTips" text="Learn More" dark position={"center"} />
      </div>
    </div>
  );
}
