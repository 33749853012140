import React, { useEffect, useState } from "react";
import ActivitiesItem from "../ActivitiesItem";
import "./Home.css";
import Button from "../Button";
import ActivitiesData from "../../data/ActivitiesData"; // Import your activities data

export default function Activities() {
  const [data, setData] = useState(ActivitiesData); // Use static data from ActivitiesData

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Sort activities by published date (newest first)
  const sortedActivities = Array.isArray(data)
    ? data.sort((a, b) => new Date(b.date) - new Date(a.date))
    : [];

  // Get the latest 4 activities or 2 based on screen size
  const latestActivities = isMobile
    ? sortedActivities.slice(0, 2)
    : sortedActivities.slice(0, 6);

  const getStyle = {
    Gap: {
      marginTop: isMobile ? "20px" : "40px",
      marginBottom: isMobile ? "20px" : "40px",
      justifyContent: isMobile ? "space-between" : "center",
    },
  };

  return (
    <div
      style={{
        backgroundColor: "#b8f7ff41",
        marginTop: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        marginBottom: "20px",
      }}
    >
      <div className="container">
        {isMobile ? (
          <h2
            className="text-center mt-3 mb-2"
            style={{ color: "#06b4cb", fontSize: "18px" }}
          >
            RECENT NEWS AND ACTIVITIES
          </h2>
        ) : (
          <h2
            className="text-center mt-5 mb-2"
            style={{ color: "#06b4cb", fontSize: "24px" }}
          >
            RECENT NEWS AND ACTIVITIES
          </h2>
        )}

        <div className="row" style={getStyle.Gap}>
          {latestActivities.map((activity) => (
            <div
              key={activity.id}
              className={`mb-4 ${
                isMobile ? "col-6" : "col-12 col-sm-6 col-md-4 col-lg-3"
              } mx-2`}
            >
              <ActivitiesItem
                id={activity.id}
                title={activity.title}
                description={activity.description}
                image={activity.image}
                author={activity.author}
                date={activity.date}
              />
            </div>
          ))}
        </div>
        <Button
          to="/all-activities"
          text="Learn More"
          dark
          position={"center"}
        />
      </div>
    </div>
  );
}
